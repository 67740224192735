'use client';
import { Roboto } from 'next/font/google';
import { createTheme } from '@mui/material/styles';

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});


const theme = createTheme({
  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: { color: '#fff' },
    h2: { color: '#fff' },
    h3: { color: '#fff' },
    h4: { color: '#fff' },
    h5: { color: '#fff' },
    h6: { color: '#fff' },
    body1: { color: '#fff', fontSize: '1.125em' },
    body2: { color: '#fff' },
  },
  palette: {
    background: {
      default: '#111927',
    },

  }
});

export default theme;