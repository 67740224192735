'use client';

import { motion } from 'framer-motion';

const AnimatedLogo = () => {
	return (
		<motion.svg width="100" height="100" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<motion.g  initial={{ strokeDasharray: 109, strokeDashoffset: 0, stroke: 'transparent' }} whileHover={{ stroke: 'hsl(164.62, 24%, 68%)', strokeWidth: 0.2, strokeDashoffset: [109, 0], transition: { duration: 0.8, repeat: Infinity } }}>
				<motion.path
					d="M23.2809 1C18.0157 10.4811 13.1265 14.5769 1 19.7375C13.7368 18.6043 18.5415 16.214 23.2809 9.07307C29.0035 16.0775 33.8256 17.8845 44 19.0398C34.3465 14.6465 29.7566 10.7875 23.2809 1Z"
					fill="url(#paint0_linear)"
				/>
				<motion.path
					d="M23.2809 14.4551C18.665 19.1618 15.0077 21.1412 6.62228 23.5249C14.2732 23.3786 17.8878 22.3506 23.2809 19.0398C28.608 21.8034 32.4241 22.6642 40.0436 23.5249C32.4489 21.2263 28.6945 19.3403 23.2809 14.4551Z"
					fill="url(#paint1_linear)"
				/>
				<motion.path
					d="M23.2809 1C18.0157 10.4811 13.1265 14.5769 1 19.7375C13.7368 18.6043 18.5415 16.214 23.2809 9.07307C29.0035 16.0775 33.8256 17.8845 44 19.0398C34.3465 14.6465 29.7566 10.7875 23.2809 1Z"
				/>
				<motion.path
					d="M23.2809 14.4551C18.665 19.1618 15.0077 21.1412 6.62228 23.5249C14.2732 23.3786 17.8878 22.3506 23.2809 19.0398C28.608 21.8034 32.4241 22.6642 40.0436 23.5249C32.4489 21.2263 28.6945 19.3403 23.2809 14.4551Z"
				/>
				<motion.path
					d="M23.385 22.2292C20.1703 25.0312 17.8014 26.0185 12.3487 26.5149C21.1476 32.4797 26.0043 32.5103 34.5254 26.5149C29.4569 25.5024 26.9063 24.6667 23.385 22.2292Z"
					fill="url(#paint2_linear)"
				/>
			</motion.g>
			<defs>
				<linearGradient id="paint0_linear" x1="23.2031" y1="0.872727" x2="23.2031" y2="19.3455" gradientUnits="userSpaceOnUse">
					<stop offset="0.699899" stopColor="#EA2027" />
					<stop offset="1" stopColor="#EE5A24" />
				</linearGradient>
				<linearGradient id="paint1_linear" x1="23.2031" y1="16.7273" x2="23.2031" y2="22.4" gradientUnits="userSpaceOnUse">
					<stop stopColor="#EA2027" />
					<stop offset="1" stopColor="#EE5A24" />
				</linearGradient>
				<linearGradient id="paint2_linear" x1="23.5547" y1="18.9091" x2="23.5547" y2="31.1273" gradientUnits="userSpaceOnUse">
					<stop stopColor="#EA2027" />
					<stop offset="1" stopColor="#EE5A24" />
				</linearGradient>
			</defs>
		</motion.svg>
	);
};

export default AnimatedLogo;
