import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/app/_components/AnimatedLogo.tsx");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/app/_components/theme.ts");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/material/CssBaseline/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/material/node/Link/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/styled-engine/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/Box/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/Container/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/createBox.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/cssVars/useCurrentColorScheme.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/GlobalStyles/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/Stack/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/Stack/Stack.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/ThemeProvider/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/Unstable_Grid/Grid.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/Unstable_Grid/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/useTheme.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/useThemeProps/index.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/system/esm/useThemeWithoutDefault.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useControlled/useControlled.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useEnhancedEffect/useEnhancedEffect.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useEventCallback/useEventCallback.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useForkRef/useForkRef.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useId/useId.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useIsFocusVisible/useIsFocusVisible.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useLazyRef/useLazyRef.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useOnMount/useOnMount.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/usePreviousProps/usePreviousProps.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@mui/utils/useTimeout/useTimeout.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/Users/eradsaar/dev/foreign_news/foreign-news/node_modules/next/dist/client/script.js")